






































import { defineComponent, computed, ref } from '@vue/composition-api';
import { useNewProjectsStore } from '@/stores/new-project';
import { useSetupWizardStore } from '@/stores/setup-wizard';
import { TemplateFile } from '@/model/store';
import { apiTemplateFileToTemplateFile } from '@/model/storeApiConverter';
import AppExpansionPanel from '@/components/wizard/ExpansionPanel.vue';
import RuntimeEnvironmentYaml from '@/components/wizard/steps/runtime/configuration/sections/environment/Yaml.vue';

const emptyInput = (): TemplateFile =>
  apiTemplateFileToTemplateFile({
    path: '.env',
    content: 'KEY=value\nSECRET={{.secrets.key}}',
    completionType: '',
  });

export default defineComponent({
  name: 'SetupRuntimeEnvironment',
  components: {
    AppExpansionPanel,
    RuntimeEnvironmentYaml,
  },
  setup() {
    const setupWizardStore = useSetupWizardStore();
    const newProjectsStore = useNewProjectsStore();

    const panels = computed<number>({
      get() {
        return setupWizardStore.selectedConfigurationPanel || 0;
      },
      set(value) {
        setupWizardStore.selectConfigurationPanel(value);
      },
    });

    const templateFiles = computed<TemplateFile[]>(() => newProjectsStore.runtimeStep?.templateFiles || [emptyInput()]);
    const projectName = computed<string>(() => newProjectsStore.projectName);
    const path = ref<string>('');
    const fileName = ref<string>('');

    const addTemplateFile = () => {
      newProjectsStore.setTemplateStepFile(emptyInput());
    };
    const setTemplateFile = (file: TemplateFile) => {
      newProjectsStore.setTemplateStepFile(file);
    };
    const removeTemplateFile = (index: number) => {
      templateFiles.value.splice(index, 1);
    };

    return {
      panels,
      templateFiles,
      projectName,
      path,
      fileName,
      addTemplateFile,
      setTemplateFile,
      removeTemplateFile,
    };
  },
});
