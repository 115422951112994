


































































import { computed, defineComponent, ref } from '@vue/composition-api';
import { useNewProjectsStore } from '@/stores/new-project';
import { useI18n } from 'vue-i18n-bridge';
import { CronJob } from 'ionos-space-api-v4';
import { Schedule, normalizeSchedule } from '@/plugins/vee-validate/cronjob';

export default defineComponent({
  name: 'SetupCronjobs',
  props: {
    editable: Boolean,
  },
  setup(props, { refs }) {
    const newProjectsStore = useNewProjectsStore();
    const { tm } = useI18n();

    const enabled = ref<boolean>(true);
    const command = ref<string>('');
    const schedule = ref<Schedule>(null);
    const headers = tm('setup.runtime.cronjobs.headers') as Array<{ name: string; value: string }>;
    const cronjobs = computed<CronJob[]>(() => newProjectsStore.deployStep.cronJobs);

    const iterationDate = tm('setup.runtime.cronjobs.addCronjob.combobox') as Array<{
      name: string;
      value: string;
      hint: string;
    }>;

    const resetCronJob = () => {
      command.value = '';
      schedule.value = null;
      enabled.value = false;

      // @ts-ignore
      refs.observer.reset();
    };

    const saveCronjob = () => {
      newProjectsStore.deployStep.cronJobs.push({
        command: command.value,
        schedule: normalizeSchedule(schedule.value),
      });
      resetCronJob();
    };

    const deleteCronjob = (index: number) => {
      newProjectsStore.deployStep.cronJobs.splice(index, 1);
    };

    return {
      cronjobs,
      headers,
      enabled,
      command,
      schedule,
      iterationDate,
      saveCronjob,
      resetCronJob,
      deleteCronjob,
    };
  },
});
