






























import { useNewProjectsStore } from '@/stores/new-project';
import { defineComponent, ref } from '@vue/composition-api';
import { storeToRefs } from 'pinia';
import AppConfigurationSettingsMenuItem from '@/components/wizard/steps/runtime/configuration/SettingsMenuItem.vue';

export default defineComponent({
  name: 'RuntimeEnvironmentYamlVariables',
  components: {
    AppConfigurationSettingsMenuItem,
  },
  props: {},
  setup() {
    const newProjectsStore = useNewProjectsStore();

    const { mailAccountSecrets, databaseSecrets } = storeToRefs(newProjectsStore);
    const opened = ref<boolean>(false);
    const onInput = (value) => {
      opened.value = value;
    };

    const copyToClipboard = async (value) => {
      await navigator.clipboard.writeText(value);
    };

    return {
      opened,
      onInput,
      mailAccountSecrets,
      databaseSecrets,
      copyToClipboard,
    };
  },
});
