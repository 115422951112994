













































import { defineComponent, PropType, ref } from '@vue/composition-api';
import RuntimeEnvironmentYamlVariables from '@/components/wizard/steps/runtime/configuration/sections/environment/Variables.vue';
import RuntimeEnvironmentYamlSecrets from '@/components/wizard/steps/runtime/configuration/sections/environment/Secrets.vue';
import { useNewProjectsStore } from '@/stores/new-project';
import { TemplateFile } from '@/model/store';

export default defineComponent({
  name: 'RuntimeEnvironmentYaml',
  components: {
    RuntimeEnvironmentYamlVariables,
    RuntimeEnvironmentYamlSecrets,
  },
  props: {
    file: {
      type: Object as PropType<TemplateFile>,
    },
  },
  setup() {
    const newProjectsStore = useNewProjectsStore();
    const opened = ref<boolean>(false);

    const dialog = ref(false);

    const onInput = (value) => {
      opened.value = value;
    };

    const setTemplateFile = (file: TemplateFile) => {
      newProjectsStore.setTemplateStepFile(file);
    };

    return {
      opened,
      onInput,
      dialog,
      setTemplateFile,
    };
  },
});
