var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',_vm._l((_vm.headers),function(item){return _c('v-col',{key:item.name,staticClass:"text--secondary headline",attrs:{"cols":"5"}},[_vm._v(_vm._s(item.name))])}),1),_vm._l((_vm.cronjobs),function(item,index){return _c('v-row',{key:index,staticClass:"align-center"},[_c('v-col',{attrs:{"cols":"5"}},[_vm._v(_vm._s(item.command))]),_c('v-col',{attrs:{"cols":"5"}},[_vm._v(_vm._s(item.schedule))]),(_vm.editable)?_c('v-col',{staticClass:"float-right",attrs:{"cols":"2"}},[_c('ionos-button',{attrs:{"small":"","primary":"","icon":""},on:{"click":function($event){return _vm.deleteCronjob(index)}}},[_c('v-icon',[_vm._v("remove")])],1)],1):_vm._e()],1)}),(_vm.enabled && _vm.editable)?_c('validation-observer',{ref:"observer",attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-row',{staticClass:"pt-4"},[_c('v-col',{attrs:{"cols":"5"}},[_c('validation-provider',{attrs:{"name":"Command","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","dense":"","error-messages":errors,"hide-details":"auto"},model:{value:(_vm.command),callback:function ($$v) {_vm.command=$$v},expression:"command"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"5"}},[_c('validation-provider',{attrs:{"rules":"required|cronjob","name":"Schedule"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-combobox',{attrs:{"items":_vm.iterationDate,"error-messages":errors,"hide-details":"auto","item-value":"value","clearable":"","dense":"","outlined":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"body-1"},[_vm._v(" "+_vm._s(item.name || item)+" ")])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"body-1"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('v-spacer'),_c('div',{staticClass:"body-1 text--secondary"},[_vm._v(" "+_vm._s(item.value)+" ")])]}}],null,true),model:{value:(_vm.schedule),callback:function ($$v) {_vm.schedule=$$v},expression:"schedule"}})]}}],null,true)})],1),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"2"}},[_c('ionos-button',{attrs:{"disabled":invalid,"icon":"","primary":""},on:{"click":function($event){return _vm.saveCronjob()}}},[_c('v-icon',[_vm._v("done")])],1),_c('ionos-button',{attrs:{"disabled":_vm.cronjobs.length === 0,"icon":""},on:{"click":function($event){return _vm.resetCronJob()}}},[_c('v-icon',[_vm._v("close")])],1)],1)],1)]}}],null,false,1166203366)}):_vm._e(),(_vm.editable)?_c('ionos-button',{staticClass:"mt-4",attrs:{"primary":"","icon":""},on:{"click":function($event){_vm.enabled = true}}},[_c('v-icon',[_vm._v("add")])],1):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }