

































































import { defineComponent, ref, watch, PropType, computed } from '@vue/composition-api';
import { VariableInput } from '@/model/store';
import { useNewProjectsStore } from '@/stores/new-project';

function emptyInput(): VariableInput {
  return { name: '', value: '', secret: false, editable: true };
}

export default defineComponent({
  name: 'SecretMenuItems',
  props: {
    initial: {
      type: Array as PropType<VariableInput[]>,
      default: () => undefined,
    },
    index: {
      type: Number,
      default: 0,
    },
    addLabel: String,
  },
  emits: ['change'],
  setup(props, { emit, refs }) {
    const newProjectsStore = useNewProjectsStore();

    const initial = props.initial;
    const input = ref<VariableInput[]>(initial);

    const enabled = ref<boolean>(true);
    const name = ref<string>('');
    const value = ref<string>('');

    const secrets = computed<VariableInput[]>(() => newProjectsStore.runtimeStep?.secrets || [emptyInput()]);

    const add = () => {
      enabled.value = true;
    };
    const remove = (index: number) => {
      newProjectsStore.runtimeStep?.secrets.splice(index, 1);
      emitChange();
    };

    const saveSecret = () => {
      newProjectsStore.runtimeStep?.secrets.push({
        ...emptyInput(),
        name: name.value,
        value: value.value,
      });

      (name.value = ''), (value.value = '');

      // @ts-ignore
      refs.observer.reset();
    };

    const cancelSecret = () => {
      name.value = '';
      value.value = '';
      enabled.value = false;

      // @ts-ignore
      refs.observer.reset();
    };

    const emitChange = () => {
      const cleanInputs = newProjectsStore.runtimeStep?.secrets.filter((input) => input.name && input.value);
      emit('change', cleanInputs);
    };

    watch(input, emitChange, { deep: true });

    const copyToClipboard = async (value) => {
      await navigator.clipboard.writeText(value);
    };

    return {
      input,
      secrets,
      name,
      value,
      enabled,
      add,
      remove,
      saveSecret,
      cancelSecret,
      copyToClipboard,
    };
  },
});
