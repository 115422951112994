





















import { useNewProjectsStore } from '@/stores/new-project';
import { computed, defineComponent, ref } from '@vue/composition-api';
import AppConfigurationSettingsMenuItem from '@/components/wizard/steps/runtime/configuration/SettingsMenuItem.vue';
import { VariableInput } from '@/model/store';
import SecretMenuItems from '@/components/wizard/steps/runtime/configuration/sections/environment/SecretMenuItems.vue';

export default defineComponent({
  name: 'RuntimeEnvironmentYamlSecrets',
  components: {
    AppConfigurationSettingsMenuItem,
    SecretMenuItems,
  },
  props: {},
  setup() {
    const newProjectsStore = useNewProjectsStore();
    const templateSecrets = computed(() => newProjectsStore.runtimeStep?.secrets);
    const opened = ref<boolean>(false);
    const onChange = (values: VariableInput[]) => {
      newProjectsStore.setRuntimeStepSecrets(values);
    };

    return {
      templateSecrets,
      opened,
      onChange,
    };
  },
});
