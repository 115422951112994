





























import { computed, defineComponent } from '@vue/composition-api';
import { useSetupWizardStore } from '@/stores/setup-wizard';

import AppWizardTitle from '@/components/wizard/forms/title/WizardTitle.vue';
import SetupRuntimeConfiguration from '@/components/wizard/steps/runtime/configuration/Index.vue';
import SetupRuntimeEnvironment from '@/components/wizard/steps/runtime/configuration/sections/environment/Index.vue';
import SetupCronjobs from '@/components/wizard/steps/runtime/cronjobs/Index.vue';

export default defineComponent({
  name: 'SetupRuntime',
  components: {
    AppWizardTitle,
    SetupRuntimeConfiguration,
    SetupRuntimeEnvironment,
    SetupCronjobs,
  },
  setup() {
    const setupWizardStore = useSetupWizardStore();

    const panels = computed<number>({
      get() {
        return setupWizardStore.selectedRuntimePanel || 0;
      },
      set(value) {
        setupWizardStore.selectRuntimePanel(value);
      },
    });

    return {
      panels,
    };
  },
});
